export default {
    path: '/channel',
    name: 'Channel',
    component: () =>
        import(
            /* webpackChunkName: "channel" */
            '../views/channel/index.vue'
        ),
    title: '渠道商',
    icon: 'basic',
    children: [
        {
            path: 'CustomerList',
            name: 'CustomerList',
            component: () =>
                import(
                    /* webpackChunkName: "channel-customer-list" */
                    '../views/channel/channel-customer-list.vue'
                ),
            title: '渠道商列表',
        },
    ],
};
